/* You can add global styles to this file, and also import other style files */
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto","Helvetica Neue",Arial,sans-serif !important;
}

.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
  background-color: #d7d3d7;
  color: #7f7b7f;

  &.status-requested {
    background-color: #6edce5;
    color: #296259;
  }

  &.status-queued {
    background-color: #ffd8b2;
    color: #805b36;
  }

  &.status-active {
    background-color: #c8e6c9;
    color: #256029;
  }

  &.status-blocked {
    background-color: #ffcdd2;
    color: #c63737;
  }

  &.status-dropped_out {
    background-color: #e3c8cb;
    color: #9e6a6a;
  }
}


.p-autocomplete-multiple-container {
  width: 100% !important;
}

.p-tabview-nav-link {
  text-decoration:none;
}

.p-buttonset .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-message-detail {
  flex: 1;
}

.p-accordion-header-link {
  text-decoration: none;
}

.p-listbox-list {
  margin-bottom: 0;
  padding: 0 !important;
}

.p-card {
  width: 100%;
}

.p-listbox-item {
  border-bottom: 1px solid #eee !important;
}

.p-listbox-item:last-child {
  border-bottom: none !important;
}

.p-sidebar-bottom {
  height: auto !important;
}

a {
  text-decoration: none !important;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Apply the animation to the spinner */
.pi-spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
}
