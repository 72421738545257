

@mixin gradient($top-color, $bottom-color) {
  background: $top-color;
  background: -moz-linear-gradient(top, $top-color 0%, $bottom-color 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top-color), color-stop(100%, $bottom-color));
  background: -webkit-linear-gradient(top, $top-color 0%, $bottom-color 100%);
  background: -o-linear-gradient(top, $top-color 0%, $bottom-color 100%);
  background: -ms-linear-gradient(top, $top-color 0%, $bottom-color 100%);
  background: linear-gradient(to bottom, $top-color 0%, $bottom-color 100%);
  background-size: 150% 150%;
}

@mixin transform-translate-x($value) {
  -webkit-transform: translate3d($value, 0, 0);
  -moz-transform: translate3d($value, 0, 0);
  -o-transform: translate3d($value, 0, 0);
  -ms-transform: translate3d($value, 0, 0);
  transform: translate3d($value, 0, 0);
}

@mixin transition($time, $type){
  -webkit-transition: all $time $type;
  -moz-transition: all $time $type;
  -o-transition: all $time $type;
  -ms-transition: all $time $type;
  transition: all $time $type;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 280px;
  display: block;
  background-size: cover;
  background-position: center center;
  overflow: auto;

  &:after,
  &:before {
    content: "";
    position: fixed;
    width: 280px;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
  }

  &:before {
    opacity: .33;
    background: #000000;
  }

  &:after {
    @include gradient(#787878, #343434);
    z-index: 3;
    opacity: 1;
  }

  &[data-image]:after,
  &.has-image:after {
    opacity: .77;
  }

  &[data-color="blue"]:after {
    @include gradient(#1F77D0, #533ce1);
  }

  &[data-color="azure"]:after {
    @include gradient(#1DC7EA, #4091ff);
  }

  &[data-color="green"]:after {
    @include gradient(#87CB16, #6dc030);
  }

  &[data-color="orange"]:after {
    @include gradient(#f39200, #ff5221);
  }

  &[data-color="red"]:after {
    @include gradient(#FB404B, #bb0502);
  }

  &[data-color="purple"]:after {
    @include gradient(#9368E9, #943bea);
  }
}

.sidebar-nav {
  margin-top: 20px;
  float: none;
  padding-left: 0;


  li {
    width: 100%;
    list-style: none;


    > a {
      color: #FFFFFF;
      margin: 5px 15px;
      opacity: .86;
      border-radius: 4px;
      display: block;
      padding: 10px 15px;
      text-decoration: none;
    }

    &:hover > a {
      background: rgba(255, 255, 255, 0.13);
      opacity: 1;
    }

    &.active > a {
      color: #FFFFFF;
      opacity: 1;
      background: rgba(255, 255, 255, 0.23);
    }

    &.separator {
      margin: 15px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  p {
    margin: 0;
    line-height: 30px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
  }

  i {
    font-size: 20px;
  }
}


.sidebar-background {
  position: fixed;
  height: 100%;
  width: 280px;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}

.main-panel {
  background: rgba(203, 203, 210, .15);
  float: right;
  width: calc(100% - 280px);
}

@media (max-width: 991px) {
  .main-panel {
    @include transform-translate-x(0px);
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    left: 0;
    width: 100%;
  }

  .sidebar {
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    @include transform-translate-x(280px);
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .nav-open .main-panel {
    left: 0;
    @include transform-translate-x(-280px);
  }

  .nav-open .sidebar {
    @include transform-translate-x(0px);
  }
}

